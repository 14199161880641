import { Box, Flex, Container, Text, Center, Link } from "@chakra-ui/react";
import React from "react";

import { colors } from "utils/colors";
import Header from "./Header";
import Orders from "./Orders";
import { useGetUrl } from "hooks/orders";

export default function Dashboard() {
  const url = useGetUrl();

  return (
    <Container bg={colors.bg} w="100%" maxW="full" h="100%" maxH="full" p="0">
      <Flex flexDirection="column" h="100vh" maxH="full" w="full" maxW="full">
        <Box flex="1">
          <Header />
        </Box>
        <Box flex="4">
          <Orders />
        </Box>
        <Center mb="10">
          <Text textColor={"white"}>
            The url of your page :{" "}
            <Link href={url} target="_blank">
              Here
            </Link>
          </Text>
        </Center>
      </Flex>
    </Container>
  );
}
