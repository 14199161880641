import Dashboard from "pages/Dashboard";
import Home from "pages/home-page/Home";
import Login from "pages/Login";
import Layout from "pages/Layout";
import { createBrowserRouter, createHashRouter } from "react-router-dom";
import Customer from "customer/pages/Customer";

export const ROOT = "/";
export const LOGIN = "/login";
export const PROTECTED = "/protected";
export const DASHBOARD = "/protected/dashboard";
export const CUSTOMER = "/cust/:rId";

export const router = createHashRouter([
  { path: ROOT, element: <Home /> },
  { path: LOGIN, element: <Login /> },
  {
    path: PROTECTED,
    element: <Layout />,
    children: [{ path: DASHBOARD, element: <Dashboard /> }],
  },
  { path: CUSTOMER, element: <Customer /> },
]);
