import {
  Center,
  FormLabel,
  Heading,
  Input,
  Box,
  Button,
  Text,
  ring,
  useToast,
  Image,
} from "@chakra-ui/react";
import {
  useGetAssets,
  useGetOrder,
  useGetResturant,
  useNotify,
} from "customer/hooks/resturant";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { set, useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import { or } from "firebase/firestore";
import { PhoneIcon } from "@chakra-ui/icons";

function OrderStatus({ orderNumber, rId }) {
  const { order, isLoading } = useGetOrder(orderNumber, rId);
  // const { notify } = useNotify();

  // useEffect(() => {
  //   if (order?.state) {
  //     notify("Ready");
  //   }
  // }, [order?.state]);

  if (isLoading) return <Center> loading... </Center>;

  if (!order)
    return (
      <Center w="60%" h="60%" bg={"#6A6E68"} borderRadius={8}>
        <Center textAlign={"center"}>
          There is no order with this number!
          <br />
          لايوجد طلب بالرقم المذكور
        </Center>
      </Center>
    );

  return (
    <Center
      w="60%"
      h="60%"
      bg={order?.state ? "#69A83E" : "#A83634"}
      borderRadius={8}
    >
      {order?.state ? (
        <Text textAlign={"center"}>
          order is ready <br /> الطلب جاهز
        </Text>
      ) : (
        <Text textAlign={"center"}>
          order is not ready <br /> الطلب غير جاهز
        </Text>
      )}
    </Center>
  );
}

export default function Customer() {
  const { rId } = useParams();
  const { resturant, isLoading } = useGetResturant(rId);
  const { logo, isLoadingLogo } = useGetAssets(rId);
  const { register, handleSubmit, reset } = useForm();
  const [orderNumber, setOrderNumber] = useState();
  const [haveOrder, setHaveOrder] = useState(false);
  const toast = useToast();
  const [orderNumberCok, setOrderNumberCok] = useState();
  const cookies = new Cookies();

  useEffect(() => {
    if (!orderNumberCok) {
      setOrderNumberCok(cookies.get("order-number"));
    }
  }, []);

  const handleOrderSubmit = (data) => {
    if (data?.orderNumber == "") {
      toast({
        title: "Add Order",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      return;
    }
    setOrderNumber(data?.orderNumber);
    setHaveOrder(true);
    cookies.set("order-number", data?.orderNumber);
  };

  if (isLoading) return <Center> loading... </Center>;

  if (!resturant) return <Center> 404 page not found </Center>;

  return (
    <Center
      w="100%"
      h="100vh"
      backgroundColor={resturant?.bgColor}
      flexDirection={"column"}
    >
      <Box mt={"20"}>
        <Image
          src={logo}
          boxSize="80px"
          objectFit="contain"
          borderRadius={"full"}
        />
      </Box>
      <Box mx="1" maxW="md" p="9" h="70%">
        <Heading mb="4" size="lg" textAlign="center">
          {resturant?.resturantName}
        </Heading>
        <form onSubmit={handleSubmit(handleOrderSubmit)}>
          <FormLabel> Enter Order Number </FormLabel>
          <Input
            borderColor={resturant?.secColor}
            defaultValue={orderNumberCok}
            type="number"
            placeholder="Order Number"
            {...register("orderNumber")}
          ></Input>
          <Button
            type="submit"
            mt="5"
            w="full"
            bgGradient={"linear(to-r, teal.500, green.500)"}
            _hover={{ bgGradient: "linear(to-r, teal.600, green.600)" }}
          >
            Submit
          </Button>
        </form>
        <Center
          mt="10"
          borderWidth={"1px"}
          borderColor={resturant?.secColor}
          h="50%"
          borderRadius={8}
        >
          {haveOrder ? (
            <OrderStatus orderNumber={orderNumber} rId={rId} />
          ) : null}
        </Center>
      </Box>
      <Text mt={"20"} textColor={"blackAlpha.500"} fontSize={"x-small"}>
        All rights reserved to Abdulrahman Alsarawi <PhoneIcon /> 0548998873
      </Text>
    </Center>
  );
}

//CCC9DC

//https://i.pinimg.com/564x/e1/fb/47/e1fb476a59aad08acf8eec570b2e3624.jpg
