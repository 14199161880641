import { position, useToast } from "@chakra-ui/react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "lib/firebaseconfig";
import { DASHBOARD } from "lib/routes";
import { useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

export function useAuth() {
  const [user, isLoading, error] = useAuthState(auth);
  return { user, isLoading, error };
}

export function useLogin() {
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const cookies = new Cookies();

  async function login({ email, password, redirectTo = DASHBOARD }) {
    setLoading(true);

    try {
      const u = await signInWithEmailAndPassword(auth, email, password);
      cookies.set("auth-token", u.user.refreshToken);
      toast({
        title: "Logged in successfuly",
        status: "success",
        isClosable: true,
        position: "top",
        duration: 7000,
      });
      navigate(redirectTo);
      setLoading(false);
      return true;
    } catch (e) {
      toast({
        title: "fieled logging in",
        description: e.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 7000,
      });
      setLoading(false);
      return false;
    }
  }

  return { login, isLoading };
}
