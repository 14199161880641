import {
  collection,
  deleteDoc,
  doc,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { db } from "lib/firebaseconfig";
import { useAuth } from "./auth";
import { useState } from "react";
import { uuidv4 } from "@firebase/util";
import { useToast } from "@chakra-ui/react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useDocumentData } from "react-firebase-hooks/firestore";

export function useAddOrder() {
  const { user } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const toast = useToast();

  async function addOrder(order) {
    setLoading(true);
    const userName = user.email.slice(0, user.email.indexOf("@"));
    const id = uuidv4();
    try {
      await setDoc(doc(db, userName, order.orderNumber), {
        ...order,
        id,
      });
      // toast({
      //   title: "order added",
      //   status: "success",
      //   isClosable: true,
      //   position: "top",
      //   duration: 5000,
      // });
    } catch (e) {
      toast({
        title: "fieled adding order",
        description: e.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }
    setLoading(false);
  }

  return { addOrder, isLoading };
}

export function useGetOrders() {
  const { user } = useAuth();
  const colName = user?.email.slice(0, user.email.indexOf("@"));
  const collectionRef = collection(db, colName ? colName : "null");
  const q = query(collectionRef);

  const [orders, isLoading] = useCollectionData(q);
  orders?.sort((a, b) => b.orderNumber - a.orderNumber);

  return { orders, isLoading };
}

export function useCallOrder() {
  const [isLoading, setLoading] = useState(false);
  const { user } = useAuth();
  const toast = useToast();

  async function callOrder(oId) {
    setLoading(true);

    const colName = user?.email.slice(0, user.email.indexOf("@"));
    const docRef = doc(db, colName ? colName : "null", oId);

    try {
      await updateDoc(docRef, {
        state: true,
      });
    } catch (e) {
      toast({
        title: "fieled calling the order",
        description: e.message,
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
    }
    setLoading(false);
  }

  return { callOrder, isLoading };
}

export function useDeleteOrders() {
  const { user } = useAuth();
  const [isLoadingD, setLoading] = useState();
  const { orders } = useGetOrders();
  const toast = useToast();

  const colName = user?.email.slice(0, user.email.indexOf("@"));

  function clearOrders() {
    setLoading(true);
    orders.forEach(async (o) => {
      const docRef = doc(db, colName ? colName : "null", o.orderNumber);
      try {
        await deleteDoc(docRef);
      } catch (e) {
        toast({
          title: "fieled deleting the orders",
          description: e.message,
          status: "error",
          isClosable: true,
          position: "top",
          duration: 5000,
        });
      }
    });
    setLoading(false);
  }

  return { clearOrders, isLoadingD };
}

export function useGetResturantData() {
  const { user } = useAuth();
  const rId = user?.email.slice(0, user.email?.indexOf("@"));
  const docRef = doc(db, "resturants", rId ? rId : "null");

  const [resturant, isLoadingData] = useDocumentData(docRef);

  return { resturant, isLoadingData, rId };
}

//user?.email.slice(0, user?.email.indexOf("@")

export function useGetUrl() {
  const { user } = useAuth();
  const rId = user?.email.slice(0, user.email?.indexOf("@"));
  const url = `/#/cust/${rId}`;

  return url;
}
