import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { LOGIN } from "lib/routes";
import { useAuth } from "hooks/auth";
import Cookies from "universal-cookie";

export default function Layout() {
  const cookies = new Cookies();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { user, isLoadind } = useAuth();
  const [isAuth, setAuth] = useState(cookies.get("auth-token"));

  useEffect(() => {
    if (!isAuth) {
      if (pathname.startsWith("/protected") && !user) {
        navigate(LOGIN);
      }
    }
  }, [pathname]);

  if (isLoadind) return "Loading...";

  return <Outlet />;
}
