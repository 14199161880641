import {
  Box,
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
} from "@chakra-ui/react";
import { useLogin } from "hooks/auth";
import React from "react";
import { useForm } from "react-hook-form";
import { colors } from "utils/colors";

export default function Login() {
  const { login, isLoading } = useLogin();
  const { register, handleSubmit, reset } = useForm();

  async function handleLogin(data) {
    await login({
      email: data.email,
      password: data.password,
    });
    reset();
  }

  return (
    <Center w="100%" h="100vh" bgColor={colors.bg}>
      <Box mx="1" maxW="md" p="9" borderRadius="lg" bgColor={colors.secondery}>
        <Heading mb="4" size="lg" textAlign="center" color={colors.bg}>
          Log In
        </Heading>
        <form onSubmit={handleSubmit(handleLogin)}>
          <FormControl py="2" isInvalid={false}>
            <FormLabel color={colors.bg}> Email </FormLabel>
            <Input
              borderColor={colors.bg}
              type="email"
              placeholder="name@email.com"
              {...register("email")}
            ></Input>
            <FormErrorMessage> error message </FormErrorMessage>
          </FormControl>
          <FormControl py="2" isInvalid={false}>
            <FormLabel color={colors.bg}> Password </FormLabel>
            <Input
              borderColor={colors.bg}
              type="password"
              placeholder="password"
              {...register("password")}
            ></Input>
            <FormErrorMessage> error message </FormErrorMessage>
          </FormControl>
          <Button
            type="submit"
            mt="5"
            w="full"
            isLoading={isLoading}
            loadingText="Logging in"
            bgGradient={"linear(to-r, teal.500, green.500)"}
            _hover={{ bgGradient: "linear(to-r, teal.600, green.600)" }}
          >
            Log in
          </Button>
        </form>
      </Box>
    </Center>
  );
}
