import {
  Center,
  Box,
  Text,
  Image,
  Flex,
  Container,
  Button,
} from "@chakra-ui/react";
import React from "react";

export default function Home() {
  return (
    <Center flexDirection={"column"} h={"100vh"} bgColor={"blackAlpha.900"}>
      <Text fontSize={"xx-large"} textColor={"whiteAlpha.900"}>
        Wait for us!
      </Text>

      <Text textColor={"whiteAlpha.900"}> - O R E A D Y - </Text>
    </Center>
    // <Box bgColor={"green.300"} h={"100vh"} w={"100%"} padding={"5"}>
    //   <Center mb={"10"}>
    //     <Text fontWeight={"medium"} textColor={"white"}>
    //       R E A D E Y
    //     </Text>
    //   </Center>
    //   <Center h={"35%"} w={"90%"}>
    //     <Center w={"100%"} h={"100%"}>
    //       <Center flex={"1"} w={"100%"} h={"100%"} mr={"3"}>
    //         <Image
    //           borderRadius={"3"}
    //           boxSize="100%"
    //           objectFit="cover"
    //           src="https://i.pinimg.com/564x/d9/ea/94/d9ea940e003e1c7b2676acde83a8722e.jpg"
    //         ></Image>
    //       </Center>
    //       <Box
    //         flex={"2"}
    //         justifyItems={"center"}
    //         bgColor={"green.500"}
    //         w={"100"}
    //         h={"100%"}
    //         borderRadius={"3"}
    //       >
    //         <Center mt={"1"}>
    //           <Text textColor={"white"} fontWeight={"semibold"}>
    //             About Us
    //           </Text>
    //         </Center>
    //         <Center mt={"10"}>
    //           <Text textColor={"white"} textAlign={"center"}>
    //             We porvide the resturant a service that enable thire customers
    //             to find thire order status
    //           </Text>
    //         </Center>
    //       </Box>
    //     </Center>
    //   </Center>
    // </Box>
  );
}
