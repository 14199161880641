import {
  Box,
  Button,
  Container,
  Text,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import { useCallOrder, useDeleteOrders, useGetOrders } from "hooks/orders";
import React from "react";
import { colors } from "utils/colors";
import Swal from "sweetalert2";
import { DeleteIcon } from "@chakra-ui/icons";

function OrderListss({ orderNumber, orderId, state }) {
  const { callOrder, isLoading } = useCallOrder();

  function orderReady() {
    callOrder(orderId);
  }

  return (
    <Button
      mr="5"
      mb="5"
      size="lg"
      isLoading={isLoading}
      onClick={orderReady}
      bg={state ? "green" : "lightblue"}
    >
      {orderNumber}
    </Button>
  );
}

export default function Orders() {
  const { orders, isLoading } = useGetOrders();
  const { clearOrders, isLoadingD } = useDeleteOrders();

  function handleClear() {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        clearOrders();
      }
    });
  }

  return (
    <Container w="full" maxW="full" height="full" padding={"58"}>
      <Flex justifyContent={"space-between"}>
        <Text color={colors.secondery} fontSize={"3xl"} mb={"5"} ml={"3"}>
          Call Orders
        </Text>
        <IconButton
          icon={<DeleteIcon />}
          onClick={handleClear}
          color={colors.secondery}
          bg={colors.bg}
          fontSize={"3xl"}
          mb={"5"}
          ml={"3"}
          _hover={{ background: colors.primery }}
        >
          Clear
        </IconButton>
      </Flex>
      <Box
        w="100%"
        height="85%"
        bg={colors.secondery}
        borderRadius="20"
        shadow={"dark-lg"}
        p="10"
        overflow={"hidden"}
        overflowY={"scroll"}
      >
        {isLoading || isLoadingD ? (
          <Text> loading... </Text>
        ) : (
          orders?.map((o) => (
            <OrderListss
              orderNumber={o.orderNumber}
              orderId={o.orderNumber}
              state={o.state}
            />
          ))
        )}
      </Box>
    </Container>
  );
}
