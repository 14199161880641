import { doc } from "firebase/firestore";
import { db } from "lib/firebaseconfig";

import { useDocumentData } from "react-firebase-hooks/firestore";
import { useDownloadURL } from "react-firebase-hooks/storage";
import { storage } from "lib/firebaseconfig";
import { ref } from "firebase/storage";
import { useState } from "react";

export function useGetResturant(rId) {
  const docRef = doc(db, "resturants", rId);

  const [resturant, isLoading] = useDocumentData(docRef);

  return { resturant, isLoading };
}

export function useGetOrder(oNum, resturantName) {
  const docRef = doc(db, resturantName, oNum);

  const [order, isLoading] = useDocumentData(docRef);

  return { order, isLoading };
}

export function useGetAssets(rId) {
  const logoRef = ref(storage, `assets/restAssets/${rId}.png`);

  const [logo, isLoadingLogo] = useDownloadURL(logoRef);

  return { logo, isLoadingLogo };
}

export function useNotify() {
  const [permisssion, setPermission] = useState();
  try {
    Notification.requestPermission().then((perm) => setPermission(perm));
  } catch (e) {
    console.error(e);
  }

  function notify(message) {
    if (permisssion === "granted") {
      new Notification(message);
    }
  }
  return { notify };
}
