import {
  Center,
  Flex,
  IconButton,
  Input,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useAddOrder, useGetResturantData } from "hooks/orders";
import React from "react";
import { useForm } from "react-hook-form";
import { colors } from "utils/colors";
import { AddIcon } from "@chakra-ui/icons";

export default function Header() {
  const { addOrder, isLoading } = useAddOrder();
  const { register, handleSubmit, reset } = useForm();
  const toast = useToast();
  const { resturant, isLoadingData, rId } = useGetResturantData();

  async function handleAdd(data) {
    if (data.orderNumber == "") {
      toast({
        title: "Add a number",
        status: "error",
        isClosable: true,
        position: "top",
        duration: 5000,
      });
      return;
    }
    const order = {
      orderNumber: data.orderNumber,
      state: false,
    };
    addOrder(order);
    reset();
  }

  return (
    <>
      <Center w="100%" height="full">
        <Text textColor={colors.secondery} fontSize={"x-large"}>
          {resturant?.resturantName}
        </Text>
        <Center w="40%" h="50%">
          <form onSubmit={handleSubmit(handleAdd)}>
            <Flex>
              <Input
                placeholder="Add order number"
                type="number"
                bg={colors.primery}
                borderColor={colors.secondery}
                color={"white"}
                {...register("orderNumber")}
              ></Input>
              <IconButton
                icon={<AddIcon />}
                type="submit"
                ml="5"
                bg={colors.secondery}
                isLoading={isLoading}
              >
                add
              </IconButton>
            </Flex>
          </form>
        </Center>
      </Center>
    </>
  );
}
